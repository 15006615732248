/*
 * 업무구분: 활동현황
 * 화 면 명: MSPAP307M
 * 화면설명: FC의 주간활동현황을 조회하는 화면
 * 접근권한: 모든 사용자
 * 작 성 일: 2022.06.15
 * 작 성 자: 조한결
 */
<template>
  <ur-page-container :show-title="true" title="주간활동현황" type="subpage" :action-type="actionType" @on-header-left-click="fn_BackBtnClicked">
    <ur-box-container direction="column" alignV="start">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area">
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="search-form">
          <div class="sch-box">
            <a class="arrow-left" @click="fn_SelectPrev">이전</a>
            <msp-bottom-select :items="lv_WeekItems.filter(item => item.isShow === true)" v-model="lv_WeekValue.value" class="ns-dropdown-sheet" />
            <a class="arrow-right" @click="fn_SelectNext">다음</a>
          </div>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-active-list">
          <mo-list :list-data="lv_MSPAP307Data">
          <template #list-item="{item}">
          <mo-list-item :expandable="item.cnt > 0" :ref="'expItem_' + item.idx" @on-item-click="(param)=>{fn_OnClickList(item, param)}" @on-expand-click="(param) =>{(fn_OnClickArrowDown(item, param))}" > <!-- @on-item-click="(param)=>{fn_OnClickList(item, param)}" -> @on-expand-click으로 수정 -->
            <div class="list-item__contents">
              <div class="list-item__contents__info">
                <div class="txt_box">
                  <span class="theads">{{item.name}}
                    <b>{{item.cnt}}</b></span>
                </div>
              </div>
            </div>
            <template v-slot:expand class="ns-gray-box">
              <div class="list-item__contents">
                <div class="active-content">
                  <div class="timeline-item" v-for="(dateGroup, index) in item.child" :key="index">
                    <h3 class="list-date" v-if="dateGroup.data.length!==0">{{dateGroup.date}}</h3> 
                    <ul>
                      <li class="list__data on" v-for="(data, index2) in dateGroup.data" :key="'' + index + index2" >
                        <dl class="list-row" v-for="(value, key, index3) in data" :key="'' + index + index2 + index3">
                          <dt class="list-column">{{key}}</dt>
                          <dd class="list-value">{{value}}</dd>
                          </dl>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </template>
          </mo-list-item>
          </template>
          </mo-list>
        </ur-box-container>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue'
  import moment from 'moment';
  import Msg from '@/systems/webkit/msg/msg'
  import Screen from '~systems/mixin/screen'
  import DateUtil from '@/ui/sp/common/exDateUtils'
// import { forEach } from '~/src/systems/acl/utils';
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPAP307M",
    screenId: "MSPAP307M",
    mixins: [Screen],
    components: {
      MspBottomSelect
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_BackBtnClicked)
      this.fn_OnLoad()
    },
    mounted() {
      let lv_vm = this
      this.$bizUtil.insSrnLog(this.$options.screenId)
      this.actionType = { actionComponent:{
        template: '<div class="ns-month-area"><mo-button class="month-btn" @click="clickLoad">이번주</mo-button></div>',
        methods: {
          clickLoad(e) {
            // button click 동작
            // lv_vm.fn_OnLoad()
            lv_vm.fn_SelThisWkly()
          }
        }
      }}
    },
    beforeDestroy () {
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_BackBtnClicked)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        DEBUG: (process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'development' ),
        lv_BefDay: moment().add(-3, 'months').format('YYYYMMDD'), // 현재부터 3달전까지만 보여달라는 요건
        lv_UserEno: '',
        cnsltNm: '',
        lv_IsScrolled: false,
        lv_SecondYn: false,
        lv_OnLoadCheck: true,        
        lv_WeekValue: {}, // 주별로 잘라넣기 위한 변수        
        lv_WeekItems: [], // 주별로 잘라넣기 위한 배열
        lv_ItemList: [],  // 잘려진 주별로 가져온 객체 리스트 0~6의 인덱스 검색
        lv_Expandable : true,
        actionType: {},
        lv_MSPAP307Data: [
          {
            name: '전화',
            expanded: false,
            cnt: 0,
            idx: 0,
            child: []
          },
          {
            name: '문자',
            expanded: false,
            cnt: 0,
            idx: 1,
            child: []
          },
          {
            name: '동의서',
            expanded: false,
            cnt: 0,
            idx: 2,
            child: []
          },
          {
            name: '보장설계',
            expanded: false,
            cnt: 0,
            idx: 3,
            child: []
          },
          {
            name: '가입설계',
            expanded: false,
            cnt: 0,
            idx: 4,
            child: []
          },
          {
            name: '청약서',
            expanded: false,
            cnt: 0,
            idx: 5,
            child: []
          },
          {
            name: '신계약',
            expanded: false,
            cnt: 0,
            idx: 6,
            child: []
          }
        ],
        lv_IsShowMSPP001P: false,
        lv_SearchYm: '', // 현재 조회한 연월
        lv_ThisWklyObj: '', // 이번주 주간 Obj
        lv_ThisMntlyIdxList: '', // 이번달 주간 인덱스 목록        
      }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    /***********************************************************************************
     * watch 함수 정의 영역                                                         *
     ***********************************************************************************/
    watch: {
      'lv_WeekValue.value' () {        
        let lv_Vm = this        
        if (lv_Vm.lv_SecondYn) {          
          lv_Vm.fn_SelListCmpltSchdl()
          lv_Vm.lv_OnLoadCheck = false
        }
        lv_Vm.lv_SecondYn = true
      }
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
    /*********************************************************
     * Function명: fn_OnLoad
     * 설명: 초기화
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_OnLoad () {
      this.lv_SecondYn = false
      if (this.lv_UserEno === '') {
        this.lv_UserEno = this.getStore('userInfo').getters.getUserInfo.userEno
      }
      if(this.$route.params.parentId === 'MSPAP105M' && !this.$bizUtil.isEmpty(this.$route.params.cnsltNo)){
        this.lv_UserEno = this.$route.params.cnsltNo
        this.cnsltNm = this.$route.params.cnsltNm
      }      
      this.fn_MonthWeekInfo()
    },    
    /*********************************************************
     * Function명: fn_MonthWeekInfo
     * 설명: 한달 주차 정보를 가져오는 프로세스
     * Params: isInit (초기 화면 시 여부), chgTyp (resrch - 초기 실패 시 재 검색, prev - 이전, , next - 이후), chgDate (검색하고자 하는 년월) 
     * Return: N/A
     *********************************************************/
    fn_MonthWeekInfo(chgTyp = '', chgDate = '') {
      let lv_Vm = this      
      if (lv_Vm.DEBUG) console.log('==========  fn_MonthWeekInfo START===========')
      let today =  moment(new Date().toISOString()).format('YYYYMMDD').toString()
      let textDate = '20231130'
      let isTest = false // 날짜 테스트 하는 경우 사용
      let srchDate = !isTest ? today : textDate
      let pStxt = chgDate !== '' ? chgDate : srchDate
      pStxt = pStxt ? pStxt.substring(0,6) : ''
      let pParams =
                { 
                  stndYm: pStxt, // 검색하고자 하는 년월
                  cnsltNo: lv_Vm.lv_UserEno
                }

      let trnstId = 'txTSSAP08S1'      
      this.post(lv_Vm, pParams, trnstId, 'S')
        .then(function (response) {
          if (response.body != null && response.body.wklyInfoListVO != null) {            
            if(response.body.wklyInfoListVO.icweklyInfoVO != null && response.body.wklyInfoListVO.icweklyInfoVO.length > 0) {
              if (lv_Vm.DEBUG) console.log('wklyInfoListVO > ', response.body.wklyInfoListVO)              
              let tmpStrYm = response.body.wklyInfoListVO.strYm
              let icweklyInfoVO = response.body.wklyInfoListVO.icweklyInfoVO
              let obj = {}                            
              let icluData = null
              // 초기 검색 시 오늘 일자로 검색, 초기 검색 후 실패 시 재검색 할 경우 chgDate(변경날짜)로 검색
              let findDate = srchDate
              let lastDayInPrvWkly = '' // 이전 주간의 마지막 날짜
              if(chgTyp === 'resrch') {
                findDate = chgDate
              } 
              /**
               * 1. 초기 검색 시 초기 화 (chgTyp === '') ,
               * 2. 초기 검색 시 실패 후 이전 달 재검색 시 (chgTyp === 'resrch')
               */
              if(chgTyp === '' || chgTyp === 'resrch') {
                lv_Vm.lv_ThisMntlyIdxList = []
              }

              icweklyInfoVO.forEach(item=> {
                obj = {
                  text : '',   // 조회일자
                  value : '',  // 시작일자
                  idx : 0,     // 주차
                }
                                
                // 기존에 데이터가 있으면, 제외시킴
                if(item.value && lv_Vm.lv_WeekItems && lv_Vm.lv_WeekItems.length > 0){                  
                  icluData = lv_Vm.lv_WeekItems.filter(wItem => wItem.value === item.value)
                  if(icluData.length > 0) {
                    return
                  }
                }

                obj.text = lv_Vm.$commonUtil.dateDashFormat(item.strYmd) + '~' + lv_Vm.$commonUtil.dateDashFormat(item.endYmd)
                obj.value = item.strYmd
                obj.idx = !lv_Vm.$bizUtil.isEmpty(item.yrClbyCnt) ? Number(item.yrClbyCnt) : 0

                if(chgTyp === '' || chgTyp === 'resrch') {                  
                  lv_Vm.lv_ThisMntlyIdxList.push(obj.idx) // 이번달 주차 (예 : 41주차 ~ 44주차 )
                  if(chgTyp === '') {
                    // 초기 정상 선택 시, 시작일자, 종료일자 검색
                    if (moment(findDate).format('YYYY-MM-DD') >= moment(item.strYmd).format('YYYY-MM-DD') && moment(findDate).format('YYYY-MM-DD') <= moment(item.endYmd).format('YYYY-MM-DD')) {
                      if(lv_Vm.DEBUG) console.log(chgTyp, findDate, item.strYmd, item.endYmd)
                      lv_Vm.lv_WeekValue =  _.cloneDeep(obj)
                      lv_Vm.lv_ThisWklyObj = _.cloneDeep(obj)
                    }
                  } else if (chgTyp === 'resrch') {                    
                    if(lv_Vm.DEBUG) console.log('resrch - previous / current', lastDayInPrvWkly, item.endYmd)
                    // 초기 선택 실패 시, 
                    // 이전 주간의 마지막 날짜보다 현재 주간의 마지막 날짜가 크면 데이터 입력
                    if (moment(lastDayInPrvWkly).format('YYYY-MM-DD') < moment(item.endYmd).format('YYYY-MM-DD')) {
                      if(lv_Vm.DEBUG) console.log(chgTyp, lastDayInPrvWkly, item.strYmd, item.endYmd)
                      lv_Vm.lv_WeekValue =  _.cloneDeep(obj)
                      lv_Vm.lv_ThisWklyObj = _.cloneDeep(obj)
                    }
                    lastDayInPrvWkly = item.endYmd // 이전 주간의 마지막 날짜
                  }
                }
                lv_Vm.lv_WeekItems.push(_.cloneDeep(obj))
              })

              let _orderBy = require('lodash/orderBy')
              lv_Vm.lv_SearchYm = tmpStrYm
              lv_Vm.lv_WeekItems = _orderBy(lv_Vm.lv_WeekItems, 'value', 'asc')
              lv_Vm.fn_SetWklyObj(chgTyp)
              lv_Vm.fn_SetShowList(chgTyp)              
              lv_Vm.fn_SelListCmpltSchdl()              
            } else if(response.body.wklyInfoListVO.icweklyInfoVO == null) {
              /**
               * 초기 조회 후 실패 시, 주간 정보가 넘어오지 않은 경우 메시지 알림, 이전 달 말로 조회
               * 초기 조회 후 실패가 아니면, 메시지만 알림
               */
              if(chgTyp === '') {                
                let prevDate = moment(new Date().toISOString()).add(-1, 'months').format('YYYYMMDD').toString()
                if (lv_Vm.DEBUG) console.log('resrch - prevDate', prevDate)                
                lv_Vm.fn_MonthWeekInfo('resrch', prevDate)
                lv_Vm.getStore('confirm').dispatch('ADD', '이번달 주간활동현황 조회 서비스를 준비 중입니다.(지난달까지 조회는 가능합니다)')                
              } else {
                lv_Vm.getStore('confirm').dispatch('ADD', '현재 월까지 조회가 가능합니다.')
              }           
            }
          }
        })
      if (lv_Vm.DEBUG) console.log('==========   fn_MonthWeekInfo END ===========')
    },
    /*********************************************************
     * Function명: fn_SetShowList
     * 설명: 목록에 보여지는 프로세스
     * Params: chgTyp (이전, 이후), wklyObj (현재 선택 주간 정보)
     * Return: N/A
     *********************************************************/
    fn_SetShowList (chgTyp) {
      if (this.DEBUG) console.log('fn_SetShowList >')
      let lv_Vm = this
      let wklyObj = lv_Vm.lv_WeekValue      
      let strIdx = 0
      let endIdx = 0
      if(chgTyp === 'prev' || chgTyp === 'next') {
        if (chgTyp === 'prev') { // 이전
          strIdx = wklyObj.idx - 3
          endIdx = wklyObj.idx
        } else if (chgTyp === 'next') { // 이후
          strIdx = wklyObj.idx
          endIdx = wklyObj.idx + 3
        }
        if (lv_Vm.DEBUG) console.log('wklyObj, chgTyp, strIdx, endIdx >', wklyObj, chgTyp, strIdx, endIdx)
        this.lv_WeekItems.forEach(item => {                    
          item.isShow = false
          /**
           * 1. 같은 년도 일 때, (년도별 주차를 구분하기 위해서)
           * 2. 선택한 주차 내 
           */          
          if ((item.value.substring(0,4) === wklyObj.value.substring(0,4)) && 
              (item.idx >= strIdx && item.idx <= endIdx)) {
            item.isShow = true
          }
        })
      } else { // 현재 월간 정보 (초기) 또는 재검색
        this.lv_WeekItems.forEach(item => {
          item.isShow = true
        })
      }
    },
    /*********************************************************
     * Function명: fn_SelThisWkly
     * 설명: 이번 주 선택 시 프로세스
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_SelThisWkly () {
      if (this.DEBUG) console.log('fn_SelThisWkly >')
      
      this.lv_WeekValue = _.cloneDeep(this.lv_ThisWklyObj)
      this.lv_WeekItems.forEach(item => {          
       item.isShow = false
      })
      this.lv_ThisMntlyIdxList.forEach(idx => {
        this.lv_WeekItems.forEach(item => {          
          if(item.idx === idx) {            
            item.isShow = true
          }
        })        
      })     
    },
    /******************************************************************************
    * Function명 : fn_SelListCmpltSchdl
    * 설명       : 주간활동의 총 건수를 가져오는 프로세스
    * sli.tss.ap.act.svc.svo.APCmpltSchhdlSVO.actTypYmdCntVO() <- 리스트 형식 0~6
   ******************************************************************************/
    fn_SelListCmpltSchdl () {     
      this.fn_PageMoveClean() 
      this.fn_CleanData()
      let lv_Vm = this
      if(lv_Vm.DEBUG) console.log('==========  fn_SelListCmpltSchdl START===========')
      if(lv_Vm.$bizUtil.isEmpty(lv_Vm.lv_WeekValue.text)) {
        return
      }
      // lv_Vm.lv_WeekValue = lv_Vm.lv_WeekItems[lv_Vm.lv_WeekValue.idx]      
      // lv_Vm.lv_WeekValue = _.cloneDeep(lv_Vm.lv_WeekItems.filter(item => item.value === lv_Vm.lv_WeekValue.value)[0])
      let pStxt = lv_Vm.lv_WeekValue.text.substring(0, 10).replace(/-/gi, '')
      let pEtxt = lv_Vm.lv_WeekValue.text.substring(11).replace(/-/gi, '')
      let pParams =
                { inqrStrYmd: pStxt, // 시작일자
                  inqrEndYmd: pEtxt, // 종료일자
                  cnsltNo: lv_Vm.lv_UserEno
                }

      let trnstId = 'txTSSAP04S1'
      lv_Vm.lv_ItemList.splice(0, lv_Vm.lv_ItemList.length)
      this.post(lv_Vm, pParams, trnstId, 'S')
        .then(function (response) {
          // 서버 데이터 가져오기
          // 다음 nextKey 값 저장

          if (response.body != null && response.body.actTypYmdCntVO != null) {
            lv_Vm.lv_ItemList = response.body.actTypYmdCntVO
            let t_OBJ = lv_Vm.lv_ItemList
            lv_Vm.lv_MSPAP307Data[0].cnt = 0 // 이번주의 전화 총 건수
            lv_Vm.lv_MSPAP307Data[1].cnt = 0 // 이번주의 문자 총 건수
            lv_Vm.lv_MSPAP307Data[2].cnt = 0 // 이번주의 총 동의서 수
            lv_Vm.lv_MSPAP307Data[3].cnt = 0 // 이번주의 총 보장분석 수
            lv_Vm.lv_MSPAP307Data[4].cnt = 0 // 이번주의 총 가입설계 수
            lv_Vm.lv_MSPAP307Data[5].cnt = 0 // 이번주의 총 청약서 수
            lv_Vm.lv_MSPAP307Data[6].cnt = 0 // 이번주의 총 신계약 수
            if(lv_Vm.DEBUG) console.log('fn_SelListCmpltSchdl', t_OBJ)
            for (let i in t_OBJ) {
              if (i === 'findLastIndex') return
              
              let child = t_OBJ[i]
              lv_Vm.lv_MSPAP307Data[0].cnt += child.taCnt // 이번주의 전화 총 건수
              lv_Vm.lv_MSPAP307Data[1].cnt += child.charCnt // 이번주의 문자 총 건수
              lv_Vm.lv_MSPAP307Data[2].cnt += child.wcnstCnt // 이번주의 총 동의서 수
              lv_Vm.lv_MSPAP307Data[3].cnt += child.covanCnt // 이번주의 총 보장설계 수
              lv_Vm.lv_MSPAP307Data[4].cnt += child.entplCnt // 이번주의 총 가입설계 수
              lv_Vm.lv_MSPAP307Data[5].cnt += child.ofrfmCnt // 이번주의 총 청약서 수
              lv_Vm.lv_MSPAP307Data[6].cnt += child.ncontCnt // 이번주의 총 신계약 수

            }
          }
        })
      if(lv_Vm.DEBUG) console.log('==========   fn_SelListCmpltSchdl END ===========')
    },
    /*********************************************************
     * Function명: fn_CleanData
     * 설명: 최초 화면을 채우기 위한 Data를 초기화
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_CleanData () {
      let lv_Vm = this
      if (lv_Vm.lv_OnLoadCheck) {
        for (let idx = 0; idx < 7; idx++) {
          lv_Vm.lv_MSPAP307Data[idx].child.lengt = 0
          lv_Vm.lv_MSPAP307Data[idx].expanded = false
          lv_Vm.lv_MSPAP307Data[idx].child = []
          lv_Vm.lv_MSPAP307Data[idx].cnt = 0
        }
      } else {
        for (let idx = 0; idx < 7; idx++) {
          lv_Vm.lv_MSPAP307Data[idx].child.lengt = 0
          lv_Vm.lv_MSPAP307Data[idx].expanded = false
          lv_Vm.lv_MSPAP307Data[idx].child = []
          lv_Vm.lv_MSPAP307Data[idx].cnt = 0
          lv_Vm.$refs['expItem_' + idx].collapse()
        }
      }
    },
    /*********************************************************
     * Function명: fn_PageMoveClean
     * 설명: 날짜 변동 시 화면을 채우기 위한 Data를 초기화
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_PageMoveClean () {
      let lv_Vm = this
      for (let idx = 0; idx < 7; idx++) {
        lv_Vm.lv_MSPAP307Data[idx].child.lengt = 0
        lv_Vm.lv_MSPAP307Data[idx].expanded = false
        lv_Vm.lv_MSPAP307Data[idx].child = []
        lv_Vm.lv_MSPAP307Data[idx].cnt = 0
        lv_Vm.$refs['expItem_' + idx].collapse()
      }
    },
    /******************************************************************************
    * Function명 : fn_SelListTelCharCntnHstr
    * 설명       : 전화문자연결이력 조회 I/F 호출
    * 값         : aPTelCharCntnHstrVO
    * actYmd @Length 8  cntcId 접촉정보 아디 agentFileID 고객아디 custNm 고객명 custCntcNm 컨텐츠 내용
    *           상기 5개는 MSPAP041에서 사용중 사용방법 보기.
    ******************************************************************************/
    fn_SelListTelCharCntnHstr: function (pIdx, actYmd, endYmd, num, item) {
      if (this.$bizUtil.isEmpty(actYmd) || this.$bizUtil.isEmpty(endYmd)) {
        return
      }

      if (String(actYmd) === String(endYmd)) {        
        this.$refs['expItem_' + item.idx].expand();
        return // 안끝나면 date로 비교
      }
      this.isLoading = true
      let lv_Vm = this
      if (lv_Vm.DEBUG) console.log('========== fn_SelListTelCharCntnHstr START ===========')
      let pParams = {}
      let trnstId = 'txTSSAP04S3'
      let evtTypCd = ''
      let evtCd = ''

      pParams =
                { inqrStrYmd: actYmd, // 시작일자
                  inqrEndYmd: actYmd, // 종료일자
                  evtTypCd: evtTypCd, // lv_Vm.evtTypCd
                  eno: lv_Vm.lv_UserEno,
                  evtCd: evtCd
                }
      this.post(lv_Vm, pParams, trnstId, 'S')
        .then(function (response) {
          console.log('response.body', response.body)
          if (response.body !== null && response.body.aPTelCharCntnHstrVO !== null && response.body.aPTelCharCntnHstrVO.length > 0) {
            let lv_CustList = response.body.aPTelCharCntnHstrVO
            let tmpObj0 = {
              date: lv_Vm.$commonUtil.dateDashFormat(actYmd),
              'data': []
            }
            let tmpObj1 = {
              date: lv_Vm.$commonUtil.dateDashFormat(actYmd),
              data: []
            }
            for ( let i in lv_CustList) {
              let item = lv_CustList[i]
              if (item.cntcId === 'HC01') { // 터치온문자
                // item.custCntcNm('터치 On')
                let tmpObj2 = {
                  '고객명': item.custNm,
                  '문자종류': '터치 On'
                }
                tmpObj1.data.push(tmpObj2)
              } else if (item.cntcId === 'HC09') { // 모바일문자
                // item.custCntcNm('스마트폰')
                let tmpObj2 = {
                  '고객명': item.custNm,
                  '문자종류': '스마트폰'
                }
                tmpObj1.data.push(tmpObj2)
              } else if (item.cntcId === 'HC08' || item.cntcId === 'HC10' || item.cntcId === 'HC11' || item.cntcId === 'SEND' || item.cntcId === 'RECI') { // 모바일(전화/계약상담전화)
              //   console.log('tests', item)
                let cntcNm = '(발신) '
                if(item.cntcId === 'HC11' || item.cntcId === 'RECI') {
                  cntcNm = '(수신) '
                }
                let tmpObj2 = {
                  '고객명': item.custNm,
                  '통화시간': cntcNm + item.custCntcNm
                }
                tmpObj0.data.push(tmpObj2)
              //   // 고객명 통화시간
              }
            }
            lv_Vm.lv_MSPAP307Data[0].child.push(tmpObj0)
            lv_Vm.lv_MSPAP307Data[1].child.push(tmpObj1)
          } else {
            // lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
        }).then(function () {
          let tMonth = Number(actYmd.toString().slice(4, 6)) - 1
          let tmp = new Date(actYmd.toString().slice(0, 4), tMonth, actYmd.toString().slice(6, 8))
          if (lv_Vm.DEBUG) console.log('before==== ', actYmd, actYmd.toString().slice(0, 4), actYmd.toString().slice(4, 6), actYmd.toString().slice(6, 8), tmp, tmp.getMonth())
          tmp.setDate(tmp.getDate() + 1)
          let ty = tmp.getFullYear().toString()
          let tm = (tmp.getMonth() + 1) >= 10 ? (tmp.getMonth() + 1).toString() : '0' + (tmp.getMonth() + 1)
          let td = tmp.getDate() >= 10 ? tmp.getDate().toString() : '0' + tmp.getDate()
          let ret = ty + tm + td
          lv_Vm.fn_SelListTelCharCntnHstr(pIdx, Number(ret), endYmd, num + 1, item)
        })
        .catch(function (error) {
          window.vue.error(error)
        })
        // {{props.item.custNm}}{{props.item.cntcId}} 를 값으로 가짐.
      if (lv_Vm.DEBUG) console.log('========== fn_SelListTelCharCntnHstr END ===========')
    },
    /******************************************************************************
    * Function명 : fn_OnClickList
    * 설명       : 리스트 클릭시 실행하는 프로세스
    ******************************************************************************/
    fn_OnClickList (item, eventParam) {
      if (item.cnt === 0) return
      // if (!item.expanded) this.fn_SmoothScroll(item)
      // item.expanded = !item.expanded
      console.log(item)
      let pIdx = item.idx
      let selDate = this.fn_GetSelDate()
      let start = selDate && selDate.startDate ? selDate.startDate : ''
      let end = selDate && selDate.endDate ? selDate.endDate : ''
      if (this.lv_MSPAP307Data[pIdx].child.length === 0) {
        if (pIdx === 0 || pIdx === 1 ) {
         this.fn_SelListTelCharCntnHstr(pIdx, start, end, 0, item)
        } else {
          let tIdx = pIdx - 1
         this.fn_SelListCmpltSchdlDtl(tIdx, start, end, 0, item)
        }
      } else {
        eventParam.defaultAction();
      }
    },
    /******************************************************************************
    * Function명 : fn_OnClickArrowDown
    * 설명       : 리스트 클릭시 실행하는 프로세스
    ******************************************************************************/
    fn_OnClickArrowDown (item, eventParam) {
      if (item.cnt === 0) return
      let pIdx = item.idx      
      let selDate = this.fn_GetSelDate()
      let start = selDate && selDate.startDate ? selDate.startDate : ''
      let end = selDate && selDate.endDate ? selDate.endDate : ''
      if (this.lv_MSPAP307Data[pIdx].child.length === 0) {
        if (pIdx === 0 || pIdx === 1 ) {
         this.fn_SelListTelCharCntnHstr(pIdx, start, end, 0, item)
        } else {
          let tIdx = pIdx - 1
         this.fn_SelListCmpltSchdlDtl(tIdx, start, end, 0, item)
        }
      } else {
        if (eventParam) {
          this.$refs['expItem_' + pIdx].expand()
        } else {
          this.$refs['expItem_' + pIdx].collapse()
        }
      }
    },
    /*********************************************************
     * Function명: fn_GetSelDate
     * 설명: 선택한 주간 정보
     * Params: N/A
     * Return: Object (startDate , endDate)
     *********************************************************/
    fn_GetSelDate() {
      let rtn = null      
      let weekArr =  (this.lv_WeekValue.text) ? this.lv_WeekValue.text.replace(/-/gi, '') : ''
      weekArr = weekArr ? weekArr.trim() : ''
      weekArr = weekArr ? weekArr.split('~') : ''
      if (weekArr && weekArr.length > 0) {
        let endDate = weekArr[1] && weekArr[1].length >= 8 ? moment(weekArr[1]).add(1, 'days').format('YYYYMMDD').toString() : '' // endDate 초과 하지 않는 날짜
        console.log('fn_GetSelDate - endDate >', endDate)
        rtn = {
          startDate: weekArr[0] ? weekArr[0] : '',
          endDate: endDate
        }
      }
      return rtn
    },
    /*********************************************************
     * Function명: fn_HasNumberText
     * 설명: str내 숫자가 있는지 판별
     * Params: value <String>
     * Return: true/ false
     *********************************************************/
    fn_HasNumberText (value) {
      return /([0-9])/.test(value)
    },
    /*********************************************************
     * Function명: fn_SelectNext
     * 설명: 다음 주 선택 시 실행
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_SelectNext () {      
      this.fn_SelectWklyItem('next')
    },    
    /*********************************************************
     * Function명: fn_SelectPrev
     * 설명: 이전 주 선택 시 실행
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_SelectPrev () {      
      this.fn_SelectWklyItem('prev')
    },
    /*********************************************************
     * Function명: fn_SelectWklyItem
     * 설명: 주 선택 시 실행
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_SelectWklyItem (chgTyp) {
      console.log('fn_SelectWklyItem >')
      let rtn = {}
      let lv_Vm = this      
      try {
        let wklyObj = lv_Vm.fn_SetWklyObj(chgTyp)
        rtn = _.cloneDeep(lv_Vm.lv_WeekItems.filter(item => item.value === wklyObj.value)[0])
        /**
         * 1. 이미 불러온 주별 정보를 재조회 하지 않기 위해 설정
         * 2. 주간 정보가 있을 때, 해당 정보를 사용 (보이는 부분은 isShow 설정)
         * 3. 주간 정보가 없을 때, 불러오고자 하는 년월을 설정하여 불러옴
         */
        if (rtn) {
          this.lv_WeekValue = rtn
          this.lv_SearchYm = rtn.value ? rtn.value : ''          

          // 검색한 주간정보가 보여지고 있지 않을 때만, 목록 새로 설정
          if(!rtn.isShow){
            this.fn_SetShowList(chgTyp, rtn)
          }
        } else {
          if(this.$bizUtil.isEmpty(this.lv_SearchYm)){
            return
          }

          let year = this.lv_SearchYm.substring(0,4) // '년'
          let month = this.lv_SearchYm.substring(4,6) - 1  // '월'
          let date = new Date(year, month, 1).toISOString()          
          let chgDate = null          
          if(chgTyp === 'prev') {
            chgDate = moment(date).add(-1, 'months').format('YYYYMMDD').toString()
          } else if(chgTyp === 'next') {
            chgDate = moment(date).add(1, 'months').format('YYYYMMDD').toString()
          }
          // console.log('date, chgTyp', date)
          if(chgDate) {
            this.fn_MonthWeekInfo(chgTyp, chgDate)
          }
        }        
      } catch (e){
        console.log('fn_SelectWklyItem error >', e)
      }      
    },
     /*********************************************************
     * Function명: fn_SetWklyObj
     * 설명: 선택한 주간 설정
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_SetWklyObj(chgTyp) {      
      let rtn = {}
      let lv_Vm = this
      try{
        if(chgTyp === '' || chgTyp === 'resrch' || lv_Vm.$bizUtil.isEmpty(lv_Vm.lv_WeekValue.value) || lv_Vm.$bizUtil.isEmpty(lv_Vm.lv_WeekItems) || lv_Vm.lv_WeekItems.length === 0) {
          return
        }

        let currIdx = 0
        lv_Vm.lv_WeekItems.forEach((item, index) => {
          if(item.value === lv_Vm.lv_WeekValue.value) {
            currIdx = index
            return
          }
        })                
        let moveIdx = 0
        if (chgTyp === 'prev') { // 이전
          moveIdx = currIdx - 1                      
        } else if (chgTyp === 'next') { // 이후
          moveIdx = currIdx + 1
        }      
        rtn = lv_Vm.lv_WeekItems[moveIdx] ? lv_Vm.lv_WeekItems[moveIdx] : ''
        if(rtn !== '') {          
          lv_Vm.lv_WeekValue = rtn
        }
      } catch(e){
        console.log('fn_SetWklyObj error', e)
      }
      return rtn
    },
    /*********************************************************
     * Function명: fn_BackBtnClicked
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_BackBtnClicked () {
      if (this.$route.query?._isMenuItem) {
        this.$router.push({name: 'MSPBC002M'})
      }else{
        this.$router.go(-1)
      }
    },
    /******************************************************************************
    * Function명 : fn_SelListCmpltSchdlDtl
    * 설명       : 주간활동현황상세리스트 조회 I/F 호출
    * sli.tss.ap.act.svc.svo.APCmpltSchdlDtlSVO.aPCmpltSchdlDtlVO <- 리스트 형식
    * cnsltNo-컨설턴트 번호, actTypScCd 활동유형구분코드, actYmd 활동일자 sno 일련번호 custNm 고객명,
    ******************************************************************************/    
     fn_SelListCmpltSchdlDtl  (actTypScCd, pDate, pEndDate, num, item1) {
      if (this.$bizUtil.isEmpty(pDate) || this.$bizUtil.isEmpty(pEndDate)) {
        return
      }

      if (String(pDate) === String(pEndDate)) {        
        this.$refs['expItem_' + item1.idx].expand();
        return // 안끝나면 date로 비교
      }
      let lv_Vm = this
      let item
      console.log('item1', item1)
      console.log('actTypScCd',actTypScCd)
      if (lv_Vm.DEBUG) console.log('========== fn_SelListCmpltSchdlDtl START ===========')
      let pParams = {}
      let trnstId = 'txTSSAP04S5'
      pParams =
                {
                  inqrEndYmd: pDate.toString(), // 종료일자
                  actTypScCd: actTypScCd,
                  cnsltNo: lv_Vm.lv_UserEno
                }
      this.post(lv_Vm, pParams, trnstId, 'S')
        .then(function (response) {
          if (response.body !== null && response.body.aPCmpltSchdlDtlVO !== null && response.body.aPCmpltSchdlDtlVO.length > 0) {
            item = response.body.aPCmpltSchdlDtlVO
            if (actTypScCd === 1) {
              // this.dayInfo.detailNm = '동의서'
              let tmpObj = {
                date: lv_Vm.$commonUtil.dateDashFormat(pDate),
                data: []
              }
              for (let i = 0; i < item.length; i++) {
                let tmpObj2 = {
                  '고객명': item[i].custNm,
                  '종류': item[i].prdtNm
                }
                tmpObj.data.push(tmpObj2)
              }
              lv_Vm.lv_MSPAP307Data[2].child.push(tmpObj)
            } else if (actTypScCd === 2) {
              // this.dayInfo.detailNm = '보장분석'
              let tmpObj = {
                date: lv_Vm.$commonUtil.dateDashFormat(pDate),
                data: []
              }
              for (let i = 0; i < item.length; i++) {
                let tmpObj2 = {
                  '고객명': item[i].custNm
                }
                tmpObj.data.push(tmpObj2)
              }
              lv_Vm.lv_MSPAP307Data[3].child.push(tmpObj)
            } else if (actTypScCd === 3) {
              // this.dayInfo.detailNm = '가입설계'
              let tmpObj = {
                date: lv_Vm.$commonUtil.dateDashFormat(pDate),
                data: []
              }
              for (let i = 0; i < item.length; i++) {
                let tMoney = '월 ' + lv_Vm.$commonUtil.numberWithCommas(item[i].prm.amount) + '원'
                let tmpObj2 = {
                  '고객명': item[i].custNm,
                  '상품명': item[i].prdtNm,
                  '보험료': tMoney
                }
                tmpObj.data.push(tmpObj2)
              }
              lv_Vm.lv_MSPAP307Data[4].child.push(tmpObj)
            } else if (actTypScCd === 4) {
              // this.dayInfo.detailNm = '청약서'
              let tmpObj = {
                date: lv_Vm.$commonUtil.dateDashFormat(pDate),
                data: []
              }
              for (let i = 0; i < item.length; i++) {
                let tMoney = '월 ' + lv_Vm.$commonUtil.numberWithCommas(item[i].prm.amount) + '원'
                let tmpObj2 = {
                  '고객명': item[i].custNm,
                  '상품명': item[i].prdtNm,
                  '보험료': tMoney
                }
                tmpObj.data.push(tmpObj2)
              }
              lv_Vm.lv_MSPAP307Data[5].child.push(tmpObj)
            } else if (actTypScCd === 5) {
              // this.dayInfo.detailNm = '신계약'
              let tmpObj = {
                date: lv_Vm.$commonUtil.dateDashFormat(pDate),
                data: []
              }
              for (let i = 0; i < item.length; i++) {
                let tMoney = '월 ' + lv_Vm.$commonUtil.numberWithCommas(item[i].prm.amount) + '원'
                let tmpObj2 = {
                  '고객명': item[i].custNm,
                  '상품명': item[i].prdtNm,
                  '보험료': tMoney
                }
                tmpObj.data.push(tmpObj2)
              }
              lv_Vm.lv_MSPAP307Data[6].child.push(tmpObj)
            }
          } else {
            // lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
        }).then(function () {
          let tMonth = Number(pDate.toString().slice(4, 6)) - 1
          let tmp = new Date(pDate.toString().slice(0, 4), tMonth, pDate.toString().slice(6, 8))
          if (lv_Vm.DEBUG) console.log('before==== ', pDate, pDate.toString().slice(0, 4), pDate.toString().slice(4, 6), pDate.toString().slice(6, 8), tmp, tmp.getMonth())
          tmp.setDate(tmp.getDate() + 1)
          let ty = tmp.getFullYear().toString()
          let tm = (tmp.getMonth() + 1) >= 10 ? (tmp.getMonth() + 1).toString() : '0' + (tmp.getMonth() + 1)
          let td = tmp.getDate() >= 10 ? tmp.getDate().toString() : '0' + tmp.getDate()
          let ret = ty + tm + td
          lv_Vm.fn_SelListCmpltSchdlDtl(actTypScCd, Number(ret), pEndDate, num + 1, item1)
        })
        .catch(function (error) {
          window.vue.error(error)
        })

      if (lv_Vm.DEBUG) console.log('==========lv_MSPAP307Data=', item)

      if (lv_Vm.DEBUG) console.log('========== fn_SelListCmpltSchdlDtl END ===========')
    },
    }
  };
</script>
<style scoped>
</style>